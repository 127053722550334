import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, Subject, catchError, concatMap, empty, map, pipe, switchMap, takeUntil, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ILogin, IResponseLogin } from 'src/app/client/components/login/login';
import { Router } from '@angular/router';
import { ErrorService } from '../error/error.service';
import { BlockUiService } from '../blockUi/blockUi.service';
import { IResponseForgotPassword } from 'src/app/client/components/forgot-password/forgot-password';
import { MessageService } from 'primeng/api';
import { IError } from 'src/app/app';
import { IResponseRole } from 'src/app/users/users';
import { IResponseConfirmEmail } from 'src/app/client/components/confirm-account/confirm-account';
import { AccountService } from 'src/app/account/account.service';
import { IResponseUser } from 'src/app/account/account';

@Injectable({ providedIn: 'root' })
export class AuthService implements OnDestroy{

    private apiUrl = environment.nakedApiUrl;
    private accountUrl = '/account'

    public userData!: IResponseUser | null;

    hasAccess = false;

    val = localStorage.getItem('content') ? JSON.parse(localStorage.getItem('content') as string) : true;

    private contentSubject = new BehaviorSubject<boolean>(this.val);
    contentActions$ = this.contentSubject.asObservable();

    private errorMessageSubject = new Subject<IError>();
    errorMessage$ = this.errorMessageSubject.asObservable();

    private userRolesSubject = new Subject<IResponseRole>();
    userRoleAction$ = this.userRolesSubject.asObservable();

    contentData$ = this.contentActions$.pipe(
        map(data => data)
    );

    public userSubject = new BehaviorSubject<IResponseUser>({} as IResponseUser);
    public currentUser$ = this.userSubject.asObservable();

    private destroySubject: Subject<void> = new Subject();

    constructor(
        private http: HttpClient,
        private router: Router,
        private errorService: ErrorService,
        private blockService: BlockUiService,
        private messageService: MessageService,
        private accountService: AccountService
    ) {
        
     }

    userData$ = this.currentUser$.pipe(
        concatMap(() => this.accountService.getUser()),
        tap(data => this.userData = data),
        map(data => data)
    )

    userRoles$ = this.userRoleAction$.pipe(
        concatMap(() => this.getRoleName().pipe(
            catchError((err) => {
                this.errorMessageSubject.next(err);
                return EMPTY
            })
        ))
    )

    authenticate(data: ILogin): Observable<IResponseLogin> {
        this.blockService.setBlockerValue(true)
        return this.http.post<IResponseLogin>(`${this.apiUrl}${this.accountUrl}/login`, data)
            .pipe(
                tap(data => {
                    localStorage.setItem('user_token', data.Data.JWToken);
                    localStorage.setItem('user_name', data.Data.UserName);
                    localStorage.setItem('user_roles', data.Data.Role);
                    localStorage.setItem('user_email', data.Data.Email);
                    localStorage.setItem('theme', data.Data.Role ? data.Data.Role.toLowerCase() : '');
                    if (data.Data.Organization && data.Data.OrganizationId) {
                        localStorage.setItem('user_organization_name', data.Data.Organization);
                        localStorage.setItem('user_organization_id', data.Data.OrganizationId.toString());
                    }
                }),
                // tap(data => this.userSubject?.next(data)),
                catchError((err) => {
                    this.messageService.add({ severity: 'error', summary: 'Login Error', detail: 'The username and password combination is incorrect.' })
                    this.errorMessageSubject.next(err);
                    return EMPTY
                })
            )
    }

    fotgotPass(email: string): Observable<IResponseForgotPassword> {
        this.blockService.setBlockerValue(true)

        const url = `${this.apiUrl}${this.accountUrl}/forgot-password`;
        return this.http.post<IResponseForgotPassword>(url, email)
            .pipe(
                map(data => data),
                tap(() => this.blockService.setBlockerValue(false)),
                tap((data) => {
                    if (data.Succeeded) {
                        this.messageService.add({ severity: 'success', summary: 'Email forogt password send', detail: data.Data.Email })
                    }
                }),
                catchError((err) => {
                    this.messageService.add({ severity: 'error', summary: 'Validation Error', detail: 'Please fill all required fields.' })
                    this.errorMessageSubject.next(err);
                    return EMPTY
                })
            )
    }


    logout() {
        const logout = this.http.post<{}>(`${this.apiUrl}${this.accountUrl}/logout`, {})
            .pipe(
                tap(() => localStorage.clear()),
                tap(() => this.userData = null),
                tap(() => this.router.navigate(['/client', 'login'])
                ))
        return logout;
    }

    user(responseData: IResponseUser) {
        this.userSubject?.next(responseData)
    }

    getRoleName(): Observable<IResponseRole> {
        const url = `${this.apiUrl}${this.accountUrl}/available-roles`;
        return this.http.get<IResponseRole>(url)
            .pipe(
                map(data => data),
                catchError(err => this.errorService.handleError(err))
            )
    }

    confirmEmail(userId:string,code:string): Observable<IResponseConfirmEmail>{
        this.blockService.setBlockerValue(true)

        const url = `${this.apiUrl}${this.accountUrl}/confirm-email?userId=${userId}&code=${code}`;


        return this.http.get<IResponseConfirmEmail>(url)
            .pipe(
                map(data => data),
                catchError((err) => {
                    this.messageService.add({ severity: 'error', summary: 'Confirm Error', detail: err.Errors })
                    this.errorMessageSubject.next(err);
                    return EMPTY
                })
            )
    }

    ngOnDestroy() {
        console.log('DESTROYED AUTH SERVICE')
        // Unsubscribe from all observables
        this.destroySubject.next();
        this.destroySubject.complete();
    }
}