import { NgModule } from '@angular/core';

import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TooltipModule } from 'primeng/tooltip';
import { TableModule } from 'primeng/table';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { TagModule } from 'primeng/tag';
import { RatingModule } from 'primeng/rating';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { ChipModule } from 'primeng/chip';
import { DialogModule } from 'primeng/dialog';
import { PaginatorModule } from 'primeng/paginator';
import { DropdownModule } from 'primeng/dropdown';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { ChipsModule } from 'primeng/chips';
import { CheckboxModule } from 'primeng/checkbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';
import { TabViewModule } from 'primeng/tabview';
import { MultiSelectModule } from 'primeng/multiselect';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { FileUploadModule } from 'primeng/fileupload';
import { CalendarModule } from 'primeng/calendar';
import { DataViewModule, DataViewLayoutOptions } from 'primeng/dataview';
import { CardModule } from 'primeng/card';
import { PanelModule } from 'primeng/panel';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SkeletonModule } from 'primeng/skeleton';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { PasswordModule } from 'primeng/password';
import { DividerModule } from 'primeng/divider';
import { AccordionModule } from 'primeng/accordion';
import { TreeModule } from 'primeng/tree';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MenuModule } from 'primeng/menu';
import { OrderListModule } from 'primeng/orderlist';
import { BadgeModule } from 'primeng/badge';
import { SliderModule } from 'primeng/slider';
import { TreeTableModule } from 'primeng/treetable';

@NgModule({
  imports: [
    ButtonModule,
    DividerModule,
    PasswordModule,
    RadioButtonModule,
    InputTextareaModule,
    InputTextModule,
    TooltipModule,
    TableModule,
    ConfirmPopupModule,
    TagModule,
    RatingModule,
    ChipModule,
    DialogModule,
    PaginatorModule,
    DropdownModule,
    AvatarModule,
    AvatarGroupModule,
    ChipsModule,
    CheckboxModule,
    InputSwitchModule,
    ToggleButtonModule,
    DynamicDialogModule,
    MessagesModule,
    ToastModule,
    TabViewModule,
    MultiSelectModule,
    ScrollPanelModule,
    FileUploadModule,
    CalendarModule,
    DataViewModule,
    CardModule,
    PanelModule,
    ConfirmDialogModule,
    SkeletonModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    AutoCompleteModule,
    AccordionModule,
    TreeModule,
    ContextMenuModule,
    MenuModule,
    OrderListModule,
    BadgeModule, 
    SliderModule,
    TreeTableModule
  ],
  exports: [
    ButtonModule,
    DividerModule,
    PasswordModule,
    InputTextareaModule,
    InputTextModule,
    RadioButtonModule,
    TooltipModule,
    TableModule,
    ConfirmPopupModule,
    TagModule,
    RatingModule,
    ChipModule,
    DialogModule,
    PaginatorModule,
    DropdownModule,
    AvatarModule,
    AvatarGroupModule,
    ChipsModule,
    CheckboxModule,
    InputSwitchModule,
    ToggleButtonModule,
    DynamicDialogModule,
    MessagesModule,
    ToastModule,
    TabViewModule,
    MultiSelectModule,
    ScrollPanelModule,
    FileUploadModule,
    CalendarModule,
    DataViewModule,
    DataViewLayoutOptions,
    CardModule,
    PanelModule,
    ConfirmDialogModule,
    SkeletonModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    AutoCompleteModule,
    AccordionModule,
    TreeModule,
    ContextMenuModule,
    MenuModule,
    OrderListModule,
    BadgeModule,
    SliderModule,
    TreeTableModule
  ]
})
export class PrimengModule { }