import { Injectable } from '@angular/core';
import { BehaviorSubject, tap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class BlockUiService {

    private blockedSubject = new BehaviorSubject<boolean>(false);
    blocker$ = this.blockedSubject.pipe();

    setBlockerValue(block: boolean) {
        this.blockedSubject.next(block)
    }
}