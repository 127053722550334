import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { EMPTY, Observable, Subject, catchError, map, tap } from 'rxjs';
import { BlockUiService } from 'src/app/shared/services/blockUi/blockUi.service';
import { ErrorService } from 'src/app/shared/services/error/error.service';
import { environment } from "src/environments/environment";
import { IError } from '../app';
import { IForgotPasswordDetail, IForgotPasswordResponse } from '../client/components/reset-password/reset-password';
import { IPasswordChange, IResponseUser, IUserDetails } from './account';

@Injectable({ providedIn: 'root' })
export class AccountService {

    constructor(private http: HttpClient, public blockService: BlockUiService, private errorService: ErrorService, private messageService: MessageService, private router: Router) { }

    loading!: boolean;

    private apiUrl = environment.nakedApiUrl;
    private usersUrl = '/account';  // URL to web api

    currentUser!: IUserDetails;

    public userEditSubject = new Subject<IUserDetails>();
    userEditAction$ = this.userEditSubject.asObservable();

    private userSubject = new Subject<IResponseUser>();
    currentUser$ = this.userSubject.asObservable();

    private errorMessageSubject = new Subject<IError>();
    errorMessage$ = this.errorMessageSubject.asObservable();

    getUser(): Observable<IResponseUser> {
        this.blockService.setBlockerValue(true)

        const url = `${this.apiUrl}${this.usersUrl}/current-user`;
        return this.http.get<IResponseUser>(url)
            .pipe(
                map(data => data),
                tap(() => this.blockService.setBlockerValue(false)),
                catchError(err => this.errorService.handleError(err))
            )
    }

    setUser(user: IUserDetails): Observable<IResponseUser> {
        
        this.blockService.setBlockerValue(true)

        const url = `${this.apiUrl}${this.usersUrl}/current-user`;

        return this.http.put<IResponseUser>(url, user)
            .pipe(
                map(data => data),
                tap(() => this.blockService.setBlockerValue(false)),
                tap((data) => {
                    if (data.Succeeded) {
                        this.messageService.add({severity: 'success', summary: 'Profile successfully updated.'})
                    } else {
                    }
                }),
                catchError(err => this.errorService.handleError(err))
            )
    }

    setPassword(password: IPasswordChange): Observable<IResponseUser> {
        this.blockService.setBlockerValue(true)

        const url = `${this.apiUrl}${this.usersUrl}/reset-password`;

        return this.http.post<IResponseUser>(url, password)
            .pipe(
                map(data => data),
                tap(() => this.blockService.setBlockerValue(false)),
                tap((data) => {
                    if (data.Succeeded) {
                        this.messageService.add({ severity: 'success', summary: 'Password successfully changed' })
                        this.router.navigate(['/account', 'settings'])
                    } else {
                    }
                }),
                catchError((err) => {
                    console.log(err);
                    this.messageService.add({ severity: 'error', summary: 'Validation Error', detail: 'Please fill all required fields.' })
                    this.errorMessageSubject.next(err);
                    return EMPTY
                })
            )
    }

    resetPassword(data: IForgotPasswordDetail): Observable<IForgotPasswordResponse> {
        this.blockService.setBlockerValue(true)

        const url = `${this.apiUrl}${this.usersUrl}/set-password`;

        return this.http.post<IForgotPasswordResponse>(url,data)
            .pipe(
                map(data => data),
                tap(() => this.blockService.setBlockerValue(false)),
                tap((data) => {
                    if (data.Succeeded) {
                        this.messageService.add({ severity: 'success', summary: 'Password successfully changed' })
                    } else {
                    }
                }),
                catchError((err) => {
                    console.log(err);
                    this.messageService.add({ severity: 'error', summary: 'Validation Error', detail: err.Message })
                    this.errorMessageSubject.next(err);
                    return EMPTY
                })
            )
    }
};