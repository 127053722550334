<div class="h-full w-full">
        <div class="w-full flex flex-row pl-10 pt-7">
            <img [src]="returnLogoImg()" />
        </div>
        <div class="w-full flex h-60 pt-14 3xl:pt-20 justify-center">
            <div class="w-1/3 flex flex-col items-center gap-6">
                <div class="flex flex-row gap-2">
                    <p class="text-4xl font-semibold text-[#334155]">Error</p>
                    <p class="text-4xl font-semibold text-[#168bf4]">404</p>
                </div>
                <div class="w-full flex flex-col items-center pt-7">
                    <span class="text-base font-normal text-[#334155] text-center">Sorry, the page you're looking for cannot be found. Feel free to explore our application from the homepage.</span>
                </div>
                <div class="pt-7">
                    <img src='assets/img/notFoundImg.svg'>
                </div>
                <div class="w-full flex justify-between">
                    <div class="w-full flex flex-col items-center pt-7">
                        <p-button type="button" (click)="backClicked()">Back to previous</p-button>
                    </div>
                    <div class="w-full flex flex-col items-center pt-7">
                        <p-button type="button" [routerLink]="getRoute()">Back to Homepage</p-button>
                    </div>
                </div>
            </div>
        </div>
</div>