import { Injectable } from '@angular/core';
import { BehaviorSubject, map, tap } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { IUserDetails } from 'src/app/users/users';


@Injectable({
  providedIn: 'root'
})
export class BackofficeService {

  userId!: IUserDetails
  public userDetails = new BehaviorSubject<any>(null);
  userDetails$ = this.userDetails.asObservable();

  constructor(private authService: AuthService) {
  }

  userDetailsAction$ = this.authService.userData$.pipe(
    map(data => data)
  )

  getUser() {
    return this.userDetails;
  }
}
