import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Observable, throwError } from 'rxjs';
import { BlockUiService } from '../blockUi/blockUi.service';

@Injectable({
    providedIn: 'root'
})

export class ErrorService {

    constructor(private blockService: BlockUiService, private messageService: MessageService, private route: ActivatedRoute, private router: Router) { }

    public handleError(err: HttpErrorResponse, fallBackUrl?: string | null): Observable<never> {
        // in a real world app, we may send the server to some remote logging infrastructure
        // instead of just logging it to the console
        let errorMessage = {};
        console.log(err);

        this.blockService.setBlockerValue(false)
    
        if (err.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          // errorMessage = `An error occurred: ${err.error.message}`;
          // this.messageService.add({ severity: 'error', summary: 'Rejected', detail: errorMessage });
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          if (err.status === 400) {
            this.messageService.add({ severity: 'error', summary: 'Validation Error', detail: err.error.Message})

            errorMessage = err.error;
          }

          if(fallBackUrl) {
            this.router.navigate([fallBackUrl]);
          }
          // errorMessage = `Backend returned code: ${err.error.detail}`;
          // this.messageService.add({ severity: 'error', summary: 'Rejected', detail: errorMessage });
        }
        // console.error(err);
        return throwError(() => errorMessage);
      }
}