<p-toast [life]="5000"></p-toast>
<aside class="aside-menu">
    <div class="flex items-center justify-center pt-4"  [class]="isMenuCollapsed ? 'gap-2' : 'gap-4'">
        <i [class]="isMenuCollapsed ? 'pi pi-times' : 'pi pi-bars'" class="cursor-pointer" (click)="toggleMenu()"></i>
        <a [routerLink]="[homePage]" class=""><img *ngIf="themeClass" [src]="returnLogoImg()" /></a>
    </div>
    <div class="flex flex-col flex-1 justify-between" *ngIf="currentUser">
        <app-menu  [roleNameStr]="roleName" [currentUser]="currentUser"></app-menu>
        <nav>
            <div class="flex items-center gap-2 px-2 py-3 border-b border-white/20"  [class]="!isMenuCollapsed ? '' : 'flex-col justify-center'">
                <div class="flex items-center justify-center shrink-0 font-bold circle-color">
                    {{fullName?.substring(0, 1)}}</div>
                <div class="min-w-0"  *ngIf="!isMenuCollapsed">
                    <div class="text-white truncate">{{fullName}}</div>
                    <div class="text-xs text-white/50 ">{{roleName}}</div>
                </div>
            </div>
            <ul>
                <li routerLinkActive="active-state" class="w-full">
                    <a [routerLink]="['/account/settings']">
                        <i class="ph-user-list"></i>
                        <span>Account Settings</span>
                    </a>
                </li>
                <li>
                    <a [routerLink]="" (click)="logout()">
                        <i class="pi pi-sign-out"></i>
                        <span>Sign Out</span>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</aside>

<header [ngClass]="backToButton ? 'no-padding' : ''" class="header">
    <div class="flex items-center justify-start gap-1">
        <div class="h-full" *ngIf="backToButton">
            <p-button type="button" [routerLink]="[backToButtonUrl]" styleClass="p-button-outlined p-button-secondary" [text]="true"
              icon="pi pi-chevron-left"></p-button>
        </div>
        <h2 class="font-semibold text-slate-700 text-base">{{pageTitle}}</h2>
    </div>
    <div class="flex justify-end gap-4">
        <div class="flex items-center gap-4" *ngIf="addNewButton">
            <a [routerLink]="[buttonActionUrl]" class="btn btn-primary">Create new {{addNewButton}}</a>
        </div>
        <div class="flex items-center gap-4" *ngIf="secondaryButton">
            <a [routerLink]="[secondaryButtonActionUrl]"class="btn btn-primary">{{secondaryButton}}</a>
        </div>
    </div>
</header>

<main>
    <div [@routeAnimations]="getRouteAnimationData()">
        <router-outlet></router-outlet>
    </div>
</main>