import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IButtonAction } from 'src/app/users/users';

@Injectable({
  providedIn: 'root'
})
export class OrganizationSharedService {

  constructor() { }

  public userButtonActionSubject = new BehaviorSubject<IButtonAction>({});
  userButtonAction$ = this.userButtonActionSubject.asObservable();

  public userBackButtonActionSubject = new BehaviorSubject<string>("");
  userBackButtonAction$ = this.userBackButtonActionSubject.asObservable();

  setButtonAction(action: IButtonAction) {
      this.userButtonActionSubject.next(action);
  }

  setBackButtonAction(action: string) {
    this.userBackButtonActionSubject.next(action);
}

}
