<div class="h-full" [@routeAnimations]="getRouteAnimationData()">
    <router-outlet></router-outlet>
</div>

<ng-container *ngIf="blockerWork$ | async as block">

    <p-blockUI [blocked]="block" class="loader-container">
        <div class="loader-container">
            <div class="relative">
                <svg class="loader-shadow" xmlns="http://www.w3.org/2000/svg" width="47" height="60" viewBox="0 0 22 13" fill="none">
                    <path d="M6.91358 12.0346C6.91358 11.9655 6.91358 11.8272 6.91358 11.7581C6.91358 11.6889 6.91358 11.689 6.91358 11.6198C6.91358 11.205 6.98272 10.8593 7.05185 10.5136C7.74321 7.33339 10.5778 4.98278 13.9654 4.98278C17.837 4.98278 20.9481 8.09389 21.0173 11.8964C21.0173 11.7581 21.0173 11.6889 21.0173 11.5507C21.0173 5.74327 16.316 0.9729 10.4395 0.9729C5.6 0.9729 1.52099 4.22228 0.276543 8.64697C0.138272 9.68401 0 10.721 0 11.7581C0 11.8272 0 11.8964 0 11.9655H6.91358V12.0346Z" fill="url(#paint0_linear_1502_3632)" />
                    <defs>
                        <linearGradient id="paint0_linear_1502_3632" x1="8.91949" y1="15.2511" x2="13.9024" y2="1.56083" gradientUnits="userSpaceOnUse">
                            <stop offset="0.3017" stop-color="#F02B70" />
                            <stop offset="0.9944" stop-color="#751637" />
                        </linearGradient>
                    </defs>
                </svg>
                <div class="loader">
                    <svg class="animated-loader">
                        <circle cx="200" cy="200" r="75" stroke-linecap="round" shape-rendering="geometricPrecision" />
                    </svg>
                </div>
            </div>
        </div>
    </p-blockUI>

</ng-container>