import { ApplicationConfig, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule, routes } from './app-routing.module';
import { AppComponent } from './app.component';

import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, provideRouter } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BlockUIModule } from 'primeng/blockui';
import { LayoutModule } from './layout/layout.module';
import { HeaderInterceptor } from './shared/https-interceptors/header.interceptor';
import { IToast } from 'src/app/shared/toast/toast';
import { SubmissionsComponent } from './submissions/submissions.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PrimengModule } from 'src/primeng/primeng.module';
import { GlobalDateService } from './global/global-date.service';
import { DatePipe } from '@angular/common';
import { PageForbiddenComponent } from './page-forbidden/page-forbidden.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes)
  ]
};

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    PageForbiddenComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    BlockUIModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    PrimengModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: true
    }),
  ],
  providers: [
    DatePipe,
    ConfirmationService,
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true
    },
   GlobalDateService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
