import { Component } from '@angular/core';
import { ChildrenOutletContexts } from '@angular/router';
import { MenuItem, PrimeNGConfig } from 'primeng/api';
import { switchMap } from 'rxjs';
import { slideInAnimation } from './animations';
import { BlockUiService } from './shared/services/blockUi/blockUi.service';
import { AuthService } from './shared/services/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [
    slideInAnimation
  ],
})
export class AppComponent {
  tabItems!: MenuItem[];

  constructor(private contexts: ChildrenOutletContexts, public blockService: BlockUiService) { }

  blockerWork$ = this.blockService.blocker$.pipe(
    switchMap(async (data) => data),
  )

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }
}