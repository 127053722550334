import { NgModule } from '@angular/core';
import { RouterModule, Routes, provideRouter } from '@angular/router';
import { BackofficeComponent } from './layout/backoffice/backoffice.component';
import { PreloginComponent } from './layout/prelogin/prelogin.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PageForbiddenComponent } from './page-forbidden/page-forbidden.component';
import { authGuard } from './auth/auth.guard';

export const routes: Routes = [

    { 
        path: '', 
        component: BackofficeComponent,
        children: [
            { 
                path: 'admin', 
                data: { breadcrumb: 'Administration', animation: 'openClosePage' },
                loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),

            },
            { 
                path: 'manager', 
                data: { breadcrumb: 'Manager', animation: 'openClosePage' },
                loadChildren: () => import('./manager/manager.module').then(m => m.ManagerModule),

            },  
            { 
                path: 'user', 
                data: { breadcrumb: 'User', animation: 'openClosePage' },
                loadChildren: () => import('./user/user.module').then(m => m.UserModule),

            },
            { 
                path: 'users', 
                data: { breadcrumb: 'User', animation: 'openClosePage' },
                loadChildren: () => import('./users/users.module').then(m => m.UsersModule),

            },
            { 
                path: 'account', 
                data: { breadcrumb: 'Account', animation: 'openClosePage' },
                loadChildren: () => import('./account/account.module').then(m => m.AccountModule),

            }
        ],
    },  
    {
        path: 'submit', 
        data: { breadcrumb: 'Submit', animation: 'openClosePage' },
        loadChildren: () => import('./submissions/submissions.module').then(m => m.SubmissionsModule),
    },
    {
        path: 'client',
        component: PreloginComponent,
        data: { breadcrumb: '', animation: 'openClosePage' },
        loadChildren: () => import('./client/client.module').then(m => m.ClientModule),
    },
    { path: '403', component: PageForbiddenComponent },
    { path: '**', component: PageNotFoundComponent },

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [
        provideRouter(routes)
      ]
  })
export class AppRoutingModule { }
