import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-forbidden.component.html',
  styleUrls: ['./page-forbidden.component.scss']
})
export class PageForbiddenComponent implements OnInit{
  
  constructor(private _location: Location) 
  {}

  themeClass!: string | null;
  userRole!: string | null;

  ngOnInit(): void {
    this.themeClass = localStorage.getItem('theme');
    this.userRole = localStorage.getItem('user_roles');
    
  }

  backClicked() {
    this._location.back();
  }

  returnLogoImg() { // trenutno samo plavu
      return 'assets/img/notfound.svg'; 
  }

  getRoute(): string {
    if(this.userRole == 'Administrator') return '/admin';
    else if(this.userRole == 'Owner' || this.userRole == 'Manager') return '/manager';
    else return '/user';
  }
}
