<p-toast [life]="5000"></p-toast>
<section class="w-full h-full flex flex-col md:flex-row">
    <aside class="hidden md:flex">
        <img src="/assets/img/roundone-logo-w.svg" alt="">
        <div class="text-center">
            <p class="text-lg md:text-xl xl:text-2xl 2xl:text-3xl text-white ">Testentainment Powerhouse</p>
        </div>
        <img src="/assets/img/prelogin-phones.png" alt="">
    </aside>
    <main class="flex flex-col flex-1">
        <router-outlet></router-outlet>
    </main>
</section>
