import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalDateService {

  constructor(private datePipe: DatePipe) { }

  formatDateToUTC(date: Date): string {
    const utcDate = new Date(date.getTime() + (date.getTimezoneOffset() * 60000));
    return this.datePipe.transform(utcDate, 'dd.MM.YYYY HH:mm:ss')!;
  }

  formatUTCToLocalDate(date: Date): Date {
    const offset = date.getTimezoneOffset() / 60;
    const hours = date.getHours();
    date.setHours(hours - offset); 
    return date;
  }
   
}
