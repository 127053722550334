import { Component, Input } from '@angular/core';
import { IResponseUser } from 'src/app/account/account';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {

  @Input() roleNameStr?: string;
  @Input() currentUser?: IResponseUser;

  hasPermission(permission: string): boolean {
    return this.currentUser?.Data.Permissions.includes(permission) ? this.currentUser?.Data.Permissions.includes(permission) : false;
  }
}