import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, ChildrenOutletContexts, NavigationEnd, Router, Scroll } from '@angular/router';
import { Subject, filter, map, takeUntil, tap } from 'rxjs';
import { IResponseUser } from 'src/app/account/account';
import { OrganizationSharedService } from 'src/app/admin/components/organizations/components/manage/organization-shared.service';
import { slideInAnimation } from 'src/app/animations';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { BackofficeService } from './backoffice.service';

@Component({
  selector: 'app-backoffice',
  host: {
    'class' : 'app-wrapper',
    '[class]': 'themeClass'
  },
  templateUrl: './backoffice.component.html',
  styleUrls: ['./backoffice.component.scss'],
  animations: [
    slideInAnimation
  ],
})
export class BackofficeComponent implements OnInit, OnDestroy {

  @Input() blockedPanel = false;

  private destroySubject: Subject<void> = new Subject();

  pageTitle = '';
  private resizeListener!: () => void;
  
  addNewButton: string | boolean = false;
  secondaryButton: string | boolean = false;
  backToButton: string | boolean = false;

  fullName!: string | null;
  roleName?: string;
  themeClass!: string | null;

  homePage!: string;
  buttonActionUrl = '';
  secondaryButtonActionUrl ='';
  backToButtonUrl = '';
  currentUser!: IResponseUser;

  menuClass!: string | null;

  constructor(private backService: BackofficeService,private router: Router, private activatedRoute: ActivatedRoute, private contexts: ChildrenOutletContexts, private authService: AuthService,private sharedService: OrganizationSharedService, private cd: ChangeDetectorRef, private renderer: Renderer2) {

    this.backService.userDetailsAction$.pipe(
        tap(currentUser => {
          if (currentUser) {
            this.fullName = currentUser.Data.FirstName + ' ' + currentUser.Data.LastName;
            this.roleName = currentUser.Data.RoleName;
          }
          switch (this.roleName) {
            case 'Administrator':
              this.homePage = 'admin';
              break;
            case 'Owner':
            case 'Manager':
              this.homePage = 'manager';
              break;
            case 'User':
              this.homePage = 'user';
              break;
  
            default:
              this.homePage = '403'
              break;
          }
          if (this.router.url === '/') {
            this.router.navigate([this.homePage])
          }
          this.currentUser = currentUser
        })
    ).subscribe()

    router.events.pipe(
      takeUntil(this.destroySubject),
      tap((val) => {
        if (val instanceof NavigationEnd) {
          if (!localStorage.getItem('user_token')) {
            this.router.navigate(['/client', 'login'])
          }
        }
      })
    ).subscribe();

    
  }

  returnLogoImg() {
    if (this.themeClass != 'administrator') {
      if(this.isMenuCollapsed){
        return 'assets/img/hr-logo-small-blue.svg'
      }
      return 'assets/img/hroundone-logo-w.svg'
    }
    else {
      if(this.isMenuCollapsed){
        return 'assets/img/hr-logo-small-red.svg'
      }
      return 'assets/img/roundone-logo-w.svg'
    }

  }

  checkIfMobile(){
    let screenWidth = window.innerWidth;
    screenWidth = window.innerWidth;
    return screenWidth < 1280;
  }
  
  ngOnInit(): void {
    this.isMenuCollapsed = this.checkIfMobile();
    
    this.resizeListener = this.renderer.listen('window', 'resize', () => {
      this.isMenuCollapsed = this.checkIfMobile();
    });

    this.sharedService.userButtonAction$.pipe(
      map((data) => {
        this.addNewButton = data ? data.ButtonAction! : false;
        this.buttonActionUrl = data ? data.ButtonActionUrl! : '';
        this.cd.detectChanges();
      })).subscribe();

      this.sharedService.userBackButtonAction$.pipe(
        map((data) => {
          this.backToButton =  data ? data : false;
          this.backToButtonUrl = data ? data : '';
          this.cd.detectChanges();
        })).subscribe();


    this.router.events.pipe(
      takeUntil(this.destroySubject),
      filter(event => event instanceof Scroll),
      map(() => {
        this.isMenuCollapsed = this.checkIfMobile();
        let child = this.activatedRoute.firstChild;
        let breadcrumb = '';
        let addNew = false;
        let addSecondaryButton = false;
        let backTo = false;
        let addNewUrl = '';
        let addNewUrlSecondary = '';
        let backToUrl = '';

        this.fullName = localStorage.getItem('user_name')
        this.roleName = typeof localStorage.getItem('user_roles') !== undefined ? localStorage.getItem('user_roles')?.toString() : ''
        this.themeClass = localStorage.getItem('theme');

        this.blockedPanel = true;

        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
          } else if (child.snapshot.data && child.snapshot.data['breadcrumb']) {
            breadcrumb = this.getHeaderText(child.snapshot.data['breadcrumb']);
            addNew = child.snapshot.data['buttonAction'];
            addSecondaryButton = child.snapshot.data['secondaryButton'];
            backTo = child.snapshot.data['buttonBackTo'];
            addNewUrl = child.snapshot.data['buttonActionUrl'];
            addNewUrlSecondary = child.snapshot.data['secondaryButtonActionUrl'];
            backToUrl = child.snapshot.data['buttonBackToUrl'];

            return {
              breadcrumb: breadcrumb,
              addNew: addNew,
              addSecondaryButton: addSecondaryButton,
              backTo: backTo,
              addNewUrl: addNewUrl,
              addNewUrlSecondary: addNewUrlSecondary,
              backToUrl: backToUrl,
            };
          } else {
            return null;
          }
        }
        return null;
      }),
      tap(data => {
        this.pageTitle = data ? data.breadcrumb : '';
        this.addNewButton = data ? data.addNew : false;
        this.secondaryButton = data ? data.addSecondaryButton : false;
        if(!this.isRouteUser()) this.backToButton = data ? data.backTo : false;
        this.buttonActionUrl = data ? data.addNewUrl : '';
        this.secondaryButtonActionUrl = data ? data.addNewUrlSecondary : '';
        if(!this.isRouteUser()) this.backToButtonUrl = data ? data.backToUrl : '';
      })
    ).subscribe();
  }

  getHeaderText(breadCrumbData: string): string {
    if(this.router.url.includes('account')) return "Account Settings";
    else if((this.router.url.includes('organizations') && this.router.url.includes('manage'))) return "Organizations";
    else if ((this.router.url.includes('users') && this.router.url.includes('edit')) || (this.router.url.includes('users') && this.router.url.includes('analytics'))) return "Edit Users";
    else return breadCrumbData;
  }

  isRouteUser() {
    if((this.router.url.includes("users") && this.router.url.includes("edit")) || (this.router.url.includes("users") && this.router.url.includes("analytics"))) return true;
    else return false;
  }

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }

  logout() {
    this.authService.logout().pipe(
      takeUntil(this.destroySubject),
    ).subscribe()
  }

  ngOnDestroy() {

    if (this.resizeListener) {
      this.resizeListener();
    }
    console.log('DESTROYED BACKOFFICE')
    // Unsubscribe from all observables
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  @HostBinding('class.collapsed') isMenuCollapsed: boolean = false;

  toggleMenu() {
      this.isMenuCollapsed = !this.isMenuCollapsed;
  }
}