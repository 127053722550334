<nav>
    <ul>
        <!-- Admin menu -->
        <!-- <li routerLinkActive="active-state" [routerLinkActiveOptions]={exact:true} *ngIf="hasPermission('admin')">
            <a [routerLink]="['admin']">
                <i class="pi pi-th-large"></i>
                <span>Dashboard</span>
            </a>
        </li>    -->
        
        <li routerLinkActive="active-state" *ngIf="hasPermission('admin/organizations')">
            <a [routerLink]="['/admin/organizations']"  >
                <i class="ph-users"></i>
                <span>Organizations</span>
            </a>
        </li>    
        <li routerLinkActive="active-state" *ngIf="hasPermission('admin/licences')">
            <a [routerLink]="['/admin/licences']"  >
                <i class="ph-user-list"></i>
                <span>Licences</span>
            </a>
        </li>    

         <!-- Manager menu -->
         <!-- <li routerLinkActive="active-state" [routerLinkActiveOptions]={exact:true} *ngIf="hasPermission('manager')">
            <a [routerLink]="['manager']">
                <i class="pi pi-th-large"></i>
                <span>Dashboard</span>
            </a>
        </li>                     -->
        <li routerLinkActive="active-state" *ngIf="hasPermission('manager/users')">
            <a [routerLink]="['/manager/users']"  >
                <i class="ph-users"></i>
                <span>Users</span>
            </a>
        </li>    
        <li routerLinkActive="active-state" *ngIf="hasPermission('manager/tests')">
            <a [routerLink]="['/manager/tests']"  >
                <i class="ph-exam"></i>
                <span>Tests</span>
            </a>
        </li>    
        <li routerLinkActive="active-state" *ngIf="hasPermission('manager/library')">
            <a [routerLink]="['/manager/library']"  >
                <i class="pi pi-book"></i>
                <span>Library</span>
            </a>
        </li>  
        <!--Dodati manager/analytics permisiju na BE-->
        <li routerLinkActive="active-state" *ngIf="hasPermission('manager/analytics')">
            <a [routerLink]="['/manager/analytics']"  >
                <i class="ph-chart-bar"></i>
                <span>Analytics</span>
            </a>
        </li>
        <li routerLinkActive="active-state" *ngIf="hasPermission('manager/groups')">
            <a [routerLink]="['/manager/groups']"  >
                <i class="ph-users"></i>
                <span>Groups</span>
            </a>
        </li>       

        <!-- user menu -->
         <!-- <li routerLinkActive="active-state" [routerLinkActiveOptions]={exact:true} *ngIf="hasPermission('user')">
            <a [routerLink]="['user']">
                <i class="pi pi-th-large"></i>
                <span>Dashboard</span>
            </a>
        </li>                     -->
        <li routerLinkActive="active-state"  *ngIf="hasPermission('user/analytics')">
            <a [routerLink]="['/user/analytics']"  >
                <i class="ph-chart-bar"></i>
                <span>Analytics</span>
            </a>
        </li>    
    </ul>
</nav>
