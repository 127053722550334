import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { catchError, throwError } from 'rxjs';
import { BlockUiService } from '../services/blockUi/blockUi.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  constructor(private router: Router, public blockService: BlockUiService, private messageService: MessageService) { }


  intercept(req: HttpRequest<never>, next: HttpHandler) {
    const body: unknown = req.body;
    const userToken = localStorage.getItem('user_token');
    
    let headerReq;
    
    if (userToken 
      && (!req.url.includes('/account/login')
      && !req.url.includes('/account/confirm-email')
      && !req.url.includes('/account/forgot-password'))) {
      if (body instanceof FormData) {
        headerReq = req.clone({ setHeaders: { 'accept': '*/*', 'Authorization': `bearer ${userToken}` } });
      } else {
        headerReq = req.clone({ setHeaders: { 'Content-Type': 'application/json', 'Accept': '*/*', 'Authorization': `bearer ${userToken}` } });
      }
    } else {
      headerReq = req.clone({ setHeaders: { 'Content-Type': 'application/json', 'Accept': '*/*' } });
    }

    // send cloned request with header to the next handler.
    // return next.handle(headerReq);
    return next.handle(headerReq).pipe(
      catchError((error: HttpErrorResponse) => {
        // Handle the error as needed
        console.log(error)
        console.log(req.url)

        switch (error.status) {
          case 401:
            this.messageService.add({ severity: 'error', summary: 'Unauthorized Access', detail: 'You are not Authorized' });
            this.router.navigate(['client', 'login']);
            break;
          case 403:
            this.messageService.add({ severity: 'error', summary: 'Forbidden Action', detail: error.error.Message });
            break;
          case 500:
            this.messageService.add({ severity: 'error', summary: 'Server Error', detail: error.error.Message });
            break;
        }
        this.blockService.setBlockerValue(false)

        return throwError(() => error.error);

      })
    );
  }

  
}